body {
  margin: 0;
  padding: 0;
  border: 0;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  border-radius: 16px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #081d2c;
  border-radius: 8px;
  outline: 1px solid rgb(116, 116, 116);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #081d2c;
  border-radius: 16px;
  outline: 1px solid rgb(116, 116, 116);
}
